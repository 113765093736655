<!--
 * @Description: 
 * @Version: 1.0
 * @Author: YaoDongxin
 * @Date: 2022-11-08 18:19:45
 * @LastEditors: YaoDongxin
 * @LastEditTime: 2022-12-26 14:41:54
-->
<template>
  <el-dialog
    :title="(type == 'create' ? '新增' : '編輯') + title"
    :visible.sync="showDialog"
    width="40%"
    :modal-append-to-body="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    @close="close"
  >
    <div v-loading="loading">
      <el-form label-width="140px" :model="form">
        <el-form-item label="曝光量">
          <el-input-number
            v-model="form.number"
            label="曝光量"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="推介時間（天）">
          <el-input-number
            v-model="form.days"
            label="推介時間（天）"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="價格（HK$）">
          <el-input-number
            v-model="form.price"
            label="價格（HK$"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="顯示權重">
          <el-input-number
            v-model="form.sort"
            label="顯示權重"
          ></el-input-number>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close()">取 消</el-button>
      <el-button type="primary" @click="confirm()" :loading="saving"
        >確 定</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'create',
    },
    item: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      saving: false,
      title: '曝光套餐',
      showDialog: false,
      loading: false,
      form: {
        number: '',
        days: '',
        price: '',
        sort: 1,
      },
    }
  },
  watch: {
    show(val) {
      this.showDialog = val
      // 打开弹窗的时候请求数据
      if (val) {
        if (this.type == 'edit') {
          this.getData(this.item.id)
        } else {
          this.form = {
            number: '',
            days: '',
            price: '',
            sort: 1,
          }
        }
      }
    },
  },
  methods: {
    async getData(id) {
      this.loading = true
      let res = await this.api.goods.getOneExposure(id)
      this.loading = false
      if (res && res.code == 0) {
        for (let p in this.form) {
          this.form[p] = res.data[p]
        }
        this.form.id = this.item.id
      }
    },
    close() {
      this.$emit('close')
    },
    confirm() {
      this.saving = true
      this.$emit('confirm', this.form)
    },
  },
}
</script>
<style scoped></style>
